import Banner from "../../sections/common/banner";
import SectionServices from "../../sections/services/section-services";
import SectionEstimation from "../../sections/home/index/section-estimation";
import SectionBooking1 from "../../sections/home/index/section-booking1";
import SectionClients1 from "../../sections/home/index2/section-clients";
import { data } from "../../../globals/data/services";

export default function ServicesPage() {
  return (
    <>
      <Banner _data={data.banner} />

      <SectionServices _data={data.services} />
      <SectionEstimation _data={data.estimation} />
      <SectionBooking1 _data={data.booking} />
      {/* <SectionClients1 _data={data.clients} /> */}
    </>
  );
}
