import React from "react";
import classes from "./styles.module.scss";
import { termsData } from "src/globals/data/terms";
import clsx from "clsx";

function TermsPage() {
  return (
    <div className="terms-page">
      {termsData.map((item, index) => (
        <div key={index} className={classes.condition}>
          <span className={classes.index}>
            {index + 1 < 10 ? `0${index + 1}` : index + 1}.
          </span>
          <p>{item}</p>
        </div>
      ))}
      <div className={clsx(classes.condition, "mt-20")}>
        Cars Bridge Inc U.S. Department of Transportation Broker’s license #
        1675922-B. This supersedes all prior written or oral representation of
        Cars Bridge Inc and constitutes the entire agreement between Shipper and
        Cars Bridge Inc and may not be changed except in writing signed by an
        officer of Cars Bridge Inc. Cars Bridge Inc has the authorization to
        cancel the order at any time.
      </div>
    </div>
  );
}

export default TermsPage;
