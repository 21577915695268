import { Route, Routes } from "react-router-dom";

import HomePage from "../app/components/home/index";
import AboutUsPage from "../app/components/misc/about-us";
import ContactPage from "../app/components/misc/contact-us";
import FaqPage from "../app/components/misc/faq";
import Error404Page from "../app/components/misc/error404";
import ServicesPage from "../app/components/services/services";
import ServicesDetailPage from "../app/components/services/services-detail";
import TermsPage from "../app/components/terms";
import PrivacyPolicy from "../app/components/privacy";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/error404" element={<Error404Page />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/services/:id" element={<ServicesDetailPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="*" element={<Error404Page />} />
      <Route element={<Error404Page />} />
    </Routes>
  );
}

export default AppRoutes;
