import React from "react";
import { ADDRESS, ADDRESS_LINK, EMAIL, PHONE_NUMBER } from "../../../globals/constants";
import { privacyData } from "../../../globals/data/privacy";

function PrivacyPolicy() {
  return (
    <div className="privacy-page">
      {privacyData.map((item, index) => (
        <div key={index} className="mt-30 mb-30">
          <h1 className="h1 mb-20 color-theme">{item.heading}</h1>
          {item.texts.map((text, textIndex) => (
            <p key={textIndex} className="mb-10">
              {text}
            </p>
          ))}
        </div>
      ))}
      <p className="mt-20">Cars Bridge Inc</p>
      <a href="https://carsbridge.com" className="color-theme mt-20 d-block">
      https://www.carsbridge.com/
      </a>
      <a
        href={`mailto:${EMAIL}`}
        target="_blank"
        className="color-theme mt-20 d-block"
      >
        {EMAIL}
      </a>
      <a
        href={ADDRESS_LINK}
        target="_blank"
        className="color-theme mt-20 d-block"
      >
        {ADDRESS}
      </a>
      <a
        href={`tel:${PHONE_NUMBER}`}
        target="_blank"
        className="color-theme mt-20 d-block"
      >
        {PHONE_NUMBER}
      </a>
    </div>
  );
}

export default PrivacyPolicy;
